import {
  fetchOperationPage,
  createLastMonth,
  editOperation,
  confirmOperation,
  confirmBussiness,
  isConfirmed,
  saveOperation,
} from 'api/finance/operationProcess';
import { getOfferList } from 'api/product/rankSearch';
import { getPermissionAllList, selectGroupAccount } from 'api/account/permission';
import { mapState, mapActions } from 'vuex';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { getPidsList } from 'api/product/rankSearch';
import { filterObject } from 'utils/utils';
import qs from 'querystring';

/*const postDatas = {
  pbDomain: "", status: "", remark: ""
};*/
export default {
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      filterForm: {
        page: 1,
        pageSize: 20,
        financeCycle: '',
      },
      total: 0,
      postData: {},
      list: [],
      accounts: [],
      loading: {
        list: false,
        submitBtn: false,
      },
      mmpRevenue: 0,
      operationFinance: {
        id: 0,
        sourceName: '',
        eventName: '',
        payout: '',
        eventCount: '',
      },
      dialog: {
        visible: false,
        title: '编辑',
        type: '',
      },
      addDialog: {
        visible: false,
        title: '新增',
        type: '',
      },
      eventCountVisible: false,
      eventCount: '',
      multipleSelection: [],
      copyArr: [],
      rules: {
        payout: [{ required: true, message: 'revenue 不能为空' }],
        eventName: [{ required: true, message: 'eventName不能为空' }],
        eventCount: [{ required: true, message: 'eventCount不能为空' }],
        sourceName: [{ required: true, message: 'sourceName不能为空' }],
        operation: [{ required: true, message: 'operation不能为空' }],
        business: [{ required: true, message: 'business不能为空' }],
        sourceId: [{ required: true, message: 'sourceId不能为空' }],
        accountManage: [{ required: true, message: 'AM不能为空' }],
        accountType: [{ required: true, message: 'AM Group不能为空' }],
        prod: [{ required: true, message: 'prod不能为空' }],
        offerId: [{ required: true, message: 'offerId不能为空' }],
        offerName: [{ required: true, message: 'offerName不能为空' }],
        pid: [{ required: true, message: 'pid不能为空' }],
        offerType: [{ required: true, message: 'offerType不能为空' }],
        bidRevenue: [{ required: true, message: 'bidRevenue不能为空' }],
        mmpRevenue: [{ required: true, message: 'mmpRevenue不能为空' }],
        financeCycle: [{ required: true, message: 'financeCycle不能为空' }],
      },
      contractData: {
        operation: '',
        business: '',
        sourceId: '',
        sourceName: '',
        accountManage: '',
        accountType: '',
        prod: '',
        offerId: '',
        offerName: '',
        eventName: '',
        payout: '',
        pid: '',
        offerType: '',
        bidRevenue: '',
        eventCount: '',
        mmpRevenue: '',
        financeCycle: '',
      },
      sumInfo: {
        bidRevenue: 0,
        mmpRevenue: 0,
        bidCount: 0,
        mmpCount: 0,
        revenueRatio: 0,
        countRatio: 0,
      },
      isConfirmed: false,
      statusList: [
        {
          label: 'created',
          value: '0',
        },
        {
          label: 'confirm',
          value: '1',
        },
        {
          label: 'AM confirm',
          value: '2',
        },
      ],
      bidAccountList: [],
      pidList: [],
      businessTypes: [
        {
          label: 'overseas',
          value: 'overseas',
        },
        {
          label: 'domestic',
          value: 'domestic',
        },
      ],
      offerList: [],
      offerListLoading: false,
    };
  },
  mounted() {
    this.getDefaultTime();
    this.getList();
    // this.isAllConfirmed();
    this.getManagers();
    this.getSourceIdsList();
    this.getPids();
    this.getAccounts();
  },
  methods: {
    // get Source
    ...mapActions('source', ['getSourceIdsList']),
    getDefaultTime() {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 1); // 设置日期为上上月
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const formattedMonth = month < 10 ? `0${month}` : month;
      let ddr = `${year}-${formattedMonth}`;
      this.filterForm.financeCycle = ddr; // 设置默认起始日期
    },
    getPids() {
      getPidsList().then((res) => {
        this.pidList = res.result;
      });
    },
    getAccounts() {
      selectGroupAccount().then((res) => {
        this.accounts = res.result;
      });
    },
    // 获取列表
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      let param = { ...this.filterForm };
      if (this.filterForm.sourceIdList) {
        param.sourceIdList = this.filterForm.sourceIdList.toString();
      }
      if (this.filterForm.pids) {
        param.pids = this.filterForm.pids.toString();
      }
      this.loading.list = true;
      // this.isAllConfirmed();
      fetchOperationPage(param)
        .then((res) => {
          if (res.code === 200) {
            this.sumInfo = res.result.pop();
            this.list = res.result;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e);
        });
    },
    // 下载excel表格
    downloadFie() {
      this.$confirm('最多下载10万条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let param = { ...this.filterForm };
          if (this.filterForm.sourceIdList) {
            param.sourceIdList = this.filterForm.sourceIdList.toString();
          }
          if (this.filterForm.pids) {
            param.pids = this.filterForm.pids.toString();
          }
          param = filterObject(param);
          let strParam = qs.stringify(param);
          let url = '/api/finance/operation/download?' + strParam;
          console.log(url);
          window.open(url, '_blank');
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: error.message,
          });
        });
    },
    // 打开弹窗
    openDialog(row) {
      this.operationFinance.id = row.id;
      this.operationFinance.sourceName = row.sourceName;
      this.operationFinance.payout = row.payout;
      this.operationFinance.eventName = row.eventName;
      this.operationFinance.eventCount = row.eventCount;
      this.dialog.visible = true;
    },
    // 打开弹窗
    openAddDialog() {
      this.addDialog.visible = true;
      if (this.$refs['addForm'] !== undefined) {
        this.$refs['addForm'].resetFields();
      }
    },

    handleEventCountDialog(row) {
      this.operationFinance.id = row.id;
      this.operationFinance.sourceName = row.sourceName;
      this.operationFinance.payout = row.payout;
      this.operationFinance.eventName = row.eventName;
      this.eventCount = row.eventCount;
      this.eventCountVisible = true;
    },

    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },
    updateOperation(data) {
      this.$refs['operationFinanceForm'].validate((valid) => {
        if (valid) {
          if (data === 'eventCount') {
            this.operationFinance.eventCount = this.eventCount;
          }
          this.loading.submitBtn = true;
          editOperation({ ...this.operationFinance })
            .then((response) => {
              if (response.success === true) {
                this.$message({
                  message: 'Update Success',
                  type: 'success',
                });
                this.loading.submitBtn = false;
                this.getList();
                this.dialog.visible = false;
                this.eventCountVisible = false;
                this.operationFinance = {
                  id: 0,
                  sourceName: '',
                  eventName: '',
                  payout: '',
                  eventCount: '',
                };
              } else {
                this.loading.submitBtn = false;
                this.$message.error('Update Error:' + response.message);
              }
            })
            .catch((e) => {
              this.loading.submitBtn = false;
              this.$message.error(e);
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    saveOperation() {
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          this.loading.submitBtn = true;
          saveOperation({ ...this.contractData })
            .then((response) => {
              if (response.success === true) {
                this.$message({
                  message: 'save Success',
                  type: 'success',
                });
                this.loading.submitBtn = false;
                this.getList();
                this.addDialog.visible = false;
                if (this.$refs['contractData']) {
                  this.$refs['contractData'].resetFields();
                }
              } else {
                this.$message.error('save Error:' + response.message);
                this.loading.submitBtn = false;
              }
            })
            .catch((e) => {
              this.loading.submitBtn = false;
              this.$message.error(e);
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    updateCount(data) {
      if (data === 'eventCount') {
        this.operationFinance.eventCount = this.eventCount;
      }
      this.loading.submitBtn = true;
      editOperation({ ...this.operationFinance })
        .then((response) => {
          if (response.success === true) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.loading.submitBtn = false;
            this.getList();
            this.dialog.visible = false;
            this.eventCountVisible = false;
          } else {
            this.$message.error('Update Error:' + response.message);
            this.loading.submitBtn = false;
          }
        })
        .catch((e) => {
          this.loading.submitBtn = false;
          this.$message.error(e);
        });
    },

    confirm() {
      let msg = '此操作将批量确认 上个月 MMP Revenue 不为空的数据?';
      if (this.copyArr && this.copyArr.length > 0) {
        msg = '此操作将批量确认 选中数据月份 MMP Revenue 不为空的数据?';
      }
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.loading.submitBtn = true;
          confirmOperation({ ids: this.copyArr }).then((response) => {
            if (response.success === true) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.getList();
              this.loading.submitBtn = false;
            } else {
              this.$message.error('Update Error:' + response.message);
              this.loading.submitBtn = false;
            }
          });
        })
        .catch(() => {
          this.loading.submitBtn = false;
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    confirmBD() {
      this.$confirm('此操作将批量生成 BD revenueList?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.loading.submitBtn = true;
          let targetList = this.copyArr.slice();
          confirmBussiness(targetList).then((response) => {
            if (response.success === true) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.loading.submitBtn = false;
              this.getList();
            } else {
              this.$message.error('Update Error:' + response.message);
              this.loading.submitBtn = false;
            }
          });
        })
        .catch(() => {
          this.loading.submitBtn = false;
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    handleConfirmStatus(row) {
      let statusNum = row.status;
      if (statusNum === '0') {
        return 'warning';
      }
      if (statusNum === '1') {
        return 'success';
      }
    },
    isAllConfirmed() {
      isConfirmed().then((response) => {
        if (response.success === true) {
          this.isConfirmed = response.result;
        } else {
          this.$message.error('Update Error:' + response.message);
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.copyArr = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        this.copyArr.push(this.multipleSelection[i].id);
      }
      console.log(this.copyArr.toString());
    },
    handleSourceChange(val) {
      for (var i = 0; i < this.sourcesList.length; i++) {
        for (var j = 0; j < this.sourcesList[i].children.length; j++) {
          if (this.sourcesList[i].children[j].value == val) {
            this.contractData.sourceName = this.sourcesList[i].children[j].company;
          }
        }
      }
    },
    // 批量新增上月数据
    batchSave() {
      this.$confirm('此操作将批量生成上月数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.loading.submitBtn = true;
          createLastMonth({}).then((res) => {
            if (res.code == 200) {
              this.$message.success('新增成功！');
              this.getList();
              this.loading.submitBtn = false;
            } else {
              this.$message.error(res.message);
              this.loading.submitBtn = false;
            }
          });
        })
        .catch(() => {
          this.loading.submitBtn = false;
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    getManagers() {
      getPermissionAllList().then((res) => {
        let values = res.result.map((item) => item.username);
        this.bidAccountList = values;
      });
    },
    // 排序监听
    // 排序监听
    sortChange(column) {
      if (column.prop !== null && column.order !== null) {
        this.filterForm.sorting = column.prop;
        this.filterForm.sortType = column.order === 'ascending' ? 'asc' : 'desc';
      }
      this.getList();
    },
    formatValue(val) {
      if (val === '0') {
        return 'create';
      } else if (val === '1') {
        return 'confirm';
      } else if (val === '2') {
        return 'AM confirm';
      } else {
        return val;
      }
    },
    formatOperationTime(row) {
      if (row.updateTime) {
        return row.updateTime;
      } else {
        return row.createTime;
      }
    },
    idDisableFun(val) {
      return !(val === '0');
    },
    computeRevenue() {
      if (this.sumInfo.bidRevenue === 0) {
        return 0;
      } else {
        let percent = Math.floor((this.sumInfo.mmpRevenue / this.sumInfo.bidRevenue) * 100);
        if (percent > 100) {
          percent = 100;
        }
        return percent;
      }
    },
    remoteMethod(query) {
      this.offerListLoading = true;
      let param = {
        page: 1,
        pageSize: 500,
        offerName: query,
      };
      getOfferList(param).then((response) => {
        this.offerListLoading = false;
        if (response.code === 200) {
          this.offerList = response.result;
        } else {
          this.$message.error(response.message);
          this.offerList = [];
        }
      });
    },
    handOffer2contractData(data) {
      // console.log('dzk:' + data);
      this.contractData.operation = data.sourceManager;
      this.contractData.business = data.business;
      this.contractData.sourceId = data.sourceId;
      this.contractData.sourceName = data.sourceName;
      this.contractData.prod = data.prodName;
      this.contractData.offerId = data.offerId;
      this.contractData.offerName = data.offerName;
      this.contractData.pid = data.pid;
      this.contractData.offerType = data.offerType;
      this.contractData.eventName = 'install';
      this.contractData.business = data.business;
      this.contractData.accountManage = data.accountManage;
      this.contractData.accountType = data.accountType;
    },
  },
  computed: {
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
  },
};
