import request from 'utils/request';
const api = {
  FINANCE_OPERATION_LIST: '/api/finance/operation/list',
  FINANCE_OPERATION_ADD: '/api/finance/operation/save',
  FINANCE_OPERATION_CREATELASTMONTH: '/api/finance/operation/createLastMonth',
  FINANCE_OPERATION_UPDATE: '/api/finance/operation/update',
  FINANCE_OPERATION_CONFIRM: '/api/finance/operation/confirm',
  FINANCE_AM_CONFIRM: '/api/finance/am/confirm',
  FINANCE_ISCONFIRMED: '/api/finance/operation/isConfirmed',
};

/**
 * 获取 运营对账流水（分页）
 * @param params
 * @returns {*}
 */
export function fetchOperationPage(params) {
  return request({
    url: api.FINANCE_OPERATION_LIST,
    method: 'get',
    params,
  });
}

export function saveOperation(data) {
  return request({
    url: api.FINANCE_OPERATION_ADD,
    method: 'post',
    data,
  });
}

export function createLastMonth(data) {
  return request({
    url: api.FINANCE_OPERATION_CREATELASTMONTH,
    method: 'post',
    data,
  });
}

export function editOperation(data) {
  return request({
    url: api.FINANCE_OPERATION_UPDATE,
    method: 'post',
    data,
  });
}

export function confirmOperation(data) {
  return request({
    url: api.FINANCE_OPERATION_CONFIRM,
    method: 'post',
    data,
  });
}

export function confirmBussiness(data) {
  return request({
    url: api.FINANCE_AM_CONFIRM,
    method: 'post',
    data,
  });
}

export function isConfirmed(params) {
  return request({
    url: api.FINANCE_ISCONFIRMED,
    method: 'get',
    params,
  });
}
