<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="80px">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-form-item label="offerIds:" class="mb5">
                <el-input
                  v-model="filterForm.offerIds"
                  placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-form-item label="OP：" prop="filterForm.operation">
                <el-select
                  v-model="filterForm.operation"
                  filterable
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                  clearable
                >
                  <el-option v-for="item in bidAccountList" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-form-item label="source(s)：" class="mb5">
                <group-select
                  v-model="filterForm.sourceIdList"
                  :data-source="sourcesList"
                  :multiple="true"
                  :loading="sourceLoading"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select Source"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-form-item label="prod" class="mb5">
                <el-input
                  clearable
                  v-model="filterForm.prod"
                  placeholder="Please enter prod"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-form-item label="pid(s):" class="mb5">
                <el-select
                  v-model="filterForm.pids"
                  multiple
                  filterable
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                >
                  <el-option v-for="item in pidList" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
              <el-form-item label="status" class="mb5">
                <el-select v-model="filterForm.status" clearable style="width: 100px">
                  <el-option
                    v-for="item in statusList"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="BD Group:" class="mb5">
                <el-select v-model="filterForm.businessType" clearable filterable>
                  <el-option
                    v-for="item in businessTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="AM" class="mb5">
                <group-select
                  v-model="filterForm.accountManage"
                  :data-source="accounts"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select AM"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="AM Group" class="mb5">
                <el-select v-model="filterForm.accountType" clearable class="w100">
                  <el-option
                    v-for="item in businessTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="period:" class="mb5">
                <el-date-picker
                  v-model="filterForm.financeCycle"
                  type="month"
                  value-format="yyyy-MM"
                  placeholder="period"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt5">
            <el-col :xs="14" :sm="14" :md="13" :lg="13" :xl="13" align="left" class="mr10">
              <el-form-item label-width="0" class="mb5">
                <span :xs="4" :sm="3" :md="3" :lg="4" :xl="3" class="ml10 font-16 cor337ab7"
                  >bid Revenue:[{{ sumInfo.bidRevenue }}]</span
                >
                <span :xs="4" :sm="3" :md="3" :lg="3" :xl="3" class="ml10 font-16 cor337ab7"
                  >MMP Revenue: [{{ sumInfo.mmpRevenue }}]</span
                >
                <span class="progress-label ml10 font-16 cor337ab7"
                  >revenue(%):
                  <el-progress
                    :percentage="sumInfo.revenueRatio"
                    status="success"
                    :text-inside="true"
                    :stroke-width="20"
                  >
                  </el-progress
                ></span>
              </el-form-item>
              <el-form-item label-width="0" class="mb5">
                <!-- <span :xs="4" :sm="3" :md="4" :lg="4" :xl="4" class="ml10 font-16 cor337ab7"
                  >confirm percent: <el-progress :percentage="computeRevenue()" status="success">
                  </el-progress></span
                > -->
                <span :xs="4" :sm="3" :md="3" :lg="4" :xl="3" class="ml10 font-16 cor337ab7"
                  >bidCount:[{{ sumInfo.bidCount }}]</span
                >
                <span :xs="4" :sm="3" :md="3" :lg="3" :xl="3" class="ml10 font-16 cor337ab7"
                  >MMPCount: [{{ sumInfo.mmpCount }}]</span
                >
                <span class="progress-label ml10 font-16 cor337ab7"
                  >count( %):
                  <el-progress
                    :percentage="sumInfo.countRatio"
                    status="success"
                    :text-inside="true"
                    :stroke-width="20"
                  >
                  </el-progress
                ></span>
              </el-form-item>
            </el-col>
            <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10" align="right">
              <el-form-item label-width="0" class="mb5">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
                <el-button type="warning" @click="confirmBD" :loading="loading.submitBtn"
                  >confirm AM finance</el-button
                >
                <el-button type="danger" :loading="loading.submitBtn" @click="confirm()"
                  >confirm op</el-button
                >
                <el-button type="warning" @click="batchSave" :loading="loading.submitBtn"
                  >Create Last Month</el-button
                >
                <el-button type="info" :loading="loading.list" @click="downloadFie"
                  >download</el-button
                >
                <el-button type="primary" @click="openAddDialog">Create</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width: 100%; overflow-x: auto"
          stripe
          border
          height="780"
          :data="list"
          tooltip-effect="dark"
          aria-setsize="mini"
          size="mini"
          class="mb10"
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column label="Operation" prop="operation" align="center"></el-table-column>
          <el-table-column label="Business" prop="business" align="center"></el-table-column>
          <el-table-column
            label="SourceName"
            prop="sourceName"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="BD Group" prop="businessType" align="center"></el-table-column>
          <el-table-column label="AM" prop="accountManage" align="center"></el-table-column>
          <el-table-column label="AM Group" prop="accountType" align="center"></el-table-column>
          <el-table-column
            label="Prod"
            prop="prod"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="OfferId" prop="offerId" align="center"></el-table-column>
          <el-table-column
            label="OfferName"
            prop="offerName"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="EventName" prop="eventName" align="center"></el-table-column>
          <el-table-column label="Revenue" prop="payout" align="center"></el-table-column>
          <el-table-column label="PID" prop="pid" align="center"></el-table-column>
          <el-table-column label="OfferType" prop="offerType" align="center"></el-table-column>
          <el-table-column
            label="BidRevenue"
            prop="bidRevenue"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column label="EventCount" prop="eventCount" align="center">
            <template slot-scope="scope">
              {{ scope.row.eventCount }}
              <i
                class="el-icon-edit"
                v-if="scope.row.operation !== 'total'"
                @click="handleEventCountDialog(scope.row)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column
            label="MMP Revenue"
            prop="mmpRevenue"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column label="Period" prop="financeCycle" align="center"></el-table-column>
          <el-table-column label="OperationTime" prop="updateTime" align="center">
            <template slot-scope="scope">
              {{ formatOperationTime(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column label="Difference" prop="difference" align="center"></el-table-column>
          <el-table-column label="Status" prop="status" align="center">
            <template slot-scope="scope">
              {{ formatValue(scope.row.status) }}
            </template>
          </el-table-column>
          <el-table-column label="Action" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                circle
                v-if="scope.row.id"
                @click="openDialog(scope.row)"
                :disabled="idDisableFun(scope.row.status)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog :title="dialog.title" :visible.sync="dialog.visible" width="30%">
          <el-form
            v-model="operationFinance"
            size="small"
            :model="operationFinance"
            ref="operationFinanceForm"
            label-width="auto"
            :rules="rules"
          >
            <el-form-item label="sourceName：" prop="sourceName">
              <el-input
                v-model="operationFinance.sourceName"
                placeholder="Please enter sourceName"
              ></el-input>
            </el-form-item>
            <el-form-item label="revenue：" prop="payout">
              <el-input
                v-model="operationFinance.payout"
                placeholder="Please enter payout"
              ></el-input>
            </el-form-item>
            <el-form-item label="eventName：" prop="eventName">
              <el-input
                v-model="operationFinance.eventName"
                placeholder="Please enter eventName"
              ></el-input>
            </el-form-item>
            <el-form-item label="eventCount：" prop="eventCount">
              <el-input
                v-model="operationFinance.eventCount"
                placeholder="Please enter eventCount"
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialog.visible = false">取 消</el-button>
            <el-button type="primary" :loading="loading.submitBtn" @click="updateOperation('')"
              >确 定</el-button
            >
          </span>
        </el-dialog>

        <!--  新增模态框-->
        <el-dialog :title="addDialog.title" :visible.sync="addDialog.visible" width="40%">
          <el-form
            :model="contractData"
            size="small"
            ref="addForm"
            label-width="auto"
            :rules="rules"
          >
            <el-form-item label="template offer：" prop="templateOffer">
              <el-select
                v-model="contractData.templateOffer"
                filterable
                remote
                clearable
                reserve-keyword
                placeholder="请输入模板offerName"
                :remote-method="remoteMethod"
                :loading="offerListLoading"
                @change="handOffer2contractData"
              >
                <el-option
                  v-for="item in offerList"
                  :key="item.offerId"
                  :label="`${item.offerId} - ${item.offerName}`"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="operation：" prop="operation">
              <el-select
                v-model="contractData.operation"
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
                clearable
              >
                <el-option v-for="item in bidAccountList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="business：" prop="business">
              <el-select
                v-model="contractData.business"
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
                clearable
              >
                <el-option v-for="item in bidAccountList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Source:" prop="sourceId">
              <group-select
                v-model="contractData.sourceId"
                :data-source="sourcesList"
                :multiple="false"
                :loading="sourceLoading"
                @change="handleSourceChange"
                reserve-keyword
                clearable
                filterable
                collapse-tags
                placeholder="Please select Source"
                class="w100"
              />
            </el-form-item>
            <el-form-item label="sourceName：" prop="sourceName">
              <el-input
                v-model="contractData.sourceName"
                placeholder="Please enter sourceName"
              ></el-input>
            </el-form-item>
            <el-form-item label="AM：" prop="accountManage">
              <el-select
                v-model="contractData.accountManage"
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
              >
                <el-option v-for="item in bidAccountList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="AM Group:" prop="accountType">
              <el-select v-model="contractData.accountType" class="w100">
                <el-option
                  v-for="item in businessTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="prod：" prop="prod">
              <el-input v-model="contractData.prod" placeholder="Please enter prod"></el-input>
            </el-form-item>

            <el-form-item label="offerId：" prop="offerId">
              <el-input
                v-model="contractData.offerId"
                placeholder="Please enter offerId"
              ></el-input>
            </el-form-item>
            <el-form-item label="offerName：" prop="offerName">
              <el-input
                v-model="contractData.offerName"
                placeholder="Please enter offerName"
              ></el-input>
            </el-form-item>
            <el-form-item label="eventName：" prop="eventName">
              <el-input
                v-model="contractData.eventName"
                placeholder="Please enter eventName"
              ></el-input>
            </el-form-item>
            <el-form-item label="revenue：" prop="payout">
              <el-input v-model="contractData.payout" placeholder="Please enter revenue"></el-input>
            </el-form-item>
            <el-form-item label="PID：" prop="pid">
              <el-select
                v-model="contractData.pid"
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
              >
                <el-option v-for="item in pidList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="offerType：" prop="offerType">
              <el-input
                v-model="contractData.offerType"
                placeholder="Please enter offerType"
              ></el-input>
            </el-form-item>
            <el-form-item label="bidRevenue：" prop="bidRevenue">
              <el-input
                v-model="contractData.bidRevenue"
                placeholder="Please enter bidRevenue"
              ></el-input>
            </el-form-item>
            <el-form-item label="eventCount：" prop="eventCount">
              <el-input
                v-model="contractData.eventCount"
                placeholder="Please enter eventCount"
              ></el-input>
            </el-form-item>
            <el-form-item label="mmpRevenue：" prop="mmpRevenue">
              <el-input
                v-model="contractData.mmpRevenue"
                placeholder="Please enter mmpRevenue"
              ></el-input>
            </el-form-item>
            <el-form-item label="period：" prop="financeCycle">
              <el-date-picker
                v-model="contractData.financeCycle"
                type="month"
                value-format="yyyy-MM"
                placeholder="period"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDialog.visible = false">取 消</el-button>
            <el-button type="primary" :loading="loading.submitBtn" @click="saveOperation"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <!--编辑eventCount模态框-->
        <el-dialog title="提示" :visible.sync="eventCountVisible" width="30%">
          <el-input v-model="eventCount" placeholder="add eventCount here"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="eventCountVisible = false">取 消</el-button>
            <el-button
              type="primary"
              :loading="loading.submitBtn"
              @click="updateCount('eventCount')"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import operationProcess from '../controllers/finance/operationProcess';
  import GroupSelect from '@/components/GroupSelect/index.vue';
  export default {
    name: 'operationProcess',
    components: { GroupSelect },
    ...operationProcess,
  };
</script>

<style scoped>
  .el-progress {
    width: 35%;
  }
  .progress-label {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
  }
</style>
